import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { Link } from 'gatsby';

interface IProps {
    title: string;
    breadCrumbs: string[];
}

const HeroContainer = styled.div`
    height: 500px;
    position: relative;
    .hero-image {
        width: 100vw;
        height: 400px;
    }
    @media ${device.tablet} {
        height: 760px;
        position: relative;
        .hero-image {
            width: 100vw;
            height: 660px;
        }
    }
    @media ${device.laptopS} {
        height: 100vh;
        position: relative;
        .hero-image {
            width: 100vw;
            height: 81vh;
        }
    }
    @media ${device.laptopL} {
        height: 100vh;
        position: relative;
        .hero-image {
            width: 100vw;
            position: relative;
            top: 100px;
            height: 77vh;
        }
    }
    @media ${device.laptopL} {
        .hero-image {
            position: relative;
            top: 0px;
        }
    }
`;

const Breadcrumbs = styled.div`
    position: absolute;
    min-height: 123px;
    padding: 20px 00px;
    color: ${colors.offWhite};
    width: 100vw;
    background: ${colors.blueExtraDark};
    h6 {
        font-family: 'Noto Serif';
        font-style: normal;
        font-weight: 600;
        min-height: 30px;
        margin: 10px 0px 00px;
        font-size: 32px;
        line-height: 132.5%;
        text-align: center;
    }
    ul {
        padding: 0px 00px;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        margin: 10px auto 0px;
    }
    a:hover {
        color: white;
        transition: 0.2s ease-in-out;
    }
    a {
        color: #c0cbcf;
        transition: 0.2s ease-in-out;
    }
    li {
        a {
            font-size: 18px;
            line-height: 27px;
            color: #c0cbcf;
            margin: 0.25em 0.7em;
            display: flex;
            align-items: center;
        }
        div {
            position: relative;
            left: 15px;
        }
    }
    @media ${device.laptopS} {
        h6 {
            margin-top: 1em;
        }
        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            li {
                a {
                    margin: 0.25em 1em;
                }
            }
        }
    }
    @media ${device.laptopL} {
        height: 183px;
    }
`;

export const Hero: React.FC<IProps> = ({ title, image, breadCrumbsUrl, breadCrumbs }) => {
    return (
        <HeroContainer>
            <GatsbyImage
                className="hero-image"
                image={image.childImageSharp.gatsbyImageData}
                alt={image.childImageSharp.gatsbyImageData.name}
                loading="eager"
            />
            <Breadcrumbs>
                <h6>{title}</h6>
                <ul>
                    <li>
                        <Link to="/">
                            <span>Home</span>{' '}
                            <StaticImage
                                src="../images/svgs/arrow-right-white.svg"
                                placeholder="none"
                                quality={100}
                                alt="blue suqare"
                            />
                        </Link>
                    </li>
                    {breadCrumbs.map((link, index: number) =>
                        index >= breadCrumbs.length - 1 ? (
                            <li style={{ fontWeight: '800' }} key={link}>
                                <Link to={breadCrumbsUrl && breadCrumbsUrl[index]}>
                                    {' '}
                                    <span>{link}</span>
                                </Link>
                            </li>
                        ) : (
                            <li key={link}>
                                <Link to={breadCrumbsUrl && breadCrumbsUrl[index]}>
                                    <span style={{ fontWeight: '800' }}>{link}</span>
                                    <StaticImage
                                        src="../images/svgs/arrow-right-white.svg"
                                        placeholder="none"
                                        quality={100}
                                        alt="blue square"
                                    />
                                </Link>
                            </li>
                        )
                    )}
                </ul>
            </Breadcrumbs>
        </HeroContainer>
    );
};
