import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { Link } from 'gatsby';

const StyledMDXBody = styled.div`
    padding: 34px 20px;
    /* main service pictures */
    a {
        color: ${colors.offBlack};
    }
    p + div {
        margin: 1em 0em;
        border-radius: 20px;
        img {
            border-radius: 20px;
            margin-bottom: 30px;
        }
    }
    p {
        font-family: 'lato';
    }
    h2,
    h3 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: ${colors.blueMedium};
    }
    ul {
        margin: 30px 0px;
        padding-left: 1.5em;
        li {
            margin: 0.25em 0em;
        }
    }
    @media ${device.tablet} {
        max-width: 750px;
        padding: 108px 20px 0px;
        margin: 0 auto;
        h2 {
            margin-top: 2em;
        }
        /* main service pictures */
        p {
        }
        p + div {
            margin: 1em 0em;
            border-radius: 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptopS} {
        max-width: 750px;
        margin: 0 auto;
        padding-top: 32px;
        /* main service pictures */
        h2 {
            margin-top: 60px;
        }
        p {
            width: 750px;
        }
        p + div {
            margin: 60px 0em 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptop} {
        max-width: 846px;
    }
    @media ${device.laptopL} {
        max-width: 1046px;
        /* main service pictures */
        p {
            width: 1046px;
        }
        p + div {
            margin: 30px 0em 0px;
            img {
                width: 1046px;
                height: 450px;
                border-radius: 20px;
            }
        }
    }
`;

const StyledServicePanels = styled.div``;

const Panel = styled.div`
    section {
        margin: 50px 0px;
    }

    div {
        margin-bottom: 15px;
        border-radius: 20px;
        transition: 0.2s ease-in-out;
        img {
            &:hover {
                transition: 0.2s ease-in-out;
                border-radius: 20px;
                transform: scale(1.1);
            }
            border-radius: 20px;
        }
    }

    p {
        padding: 0em;
        margin: 0;
        span {
            color: ${colors.blueMedium};
            font-weight: 600;
        }
    }
    @media ${device.laptop} {
        section {
            align-items: center;
            display: flex;
            p {
                width: 465px;
                margin-bottom: 35px;
            }
            div {
                width: 40%;
                height: 230px;
            }
        }
        .regular {
            flex-direction: row;
            justify-content: space-between;
        }
        .reverse {
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }
`;

export const ServicePanels = ({ services }) => {
    // if we need to remove the page itself use this and map through only this. no edges needed
    // const newServices=services.edges.slice(0);
    return (
        <StyledMDXBody>
            <StyledServicePanels className="container">
                {services.edges.map((service, index) => {
                    return (
                        <Link
                            key={service.node.frontmatter.title}
                            to={service.node.frontmatter.slug}
                        >
                            <Panel>
                                <section className={index % 2 == 0 ? `reverse` : 'regular'}>
                                    <GatsbyImage
                                        image={
                                            service.node.frontmatter.images[0].childrenImageSharp[0]
                                                .gatsbyImageData
                                        }
                                    />
                                    <p>
                                        <span>{service.node.frontmatter.title}</span> -{' '}
                                        {service.node.frontmatter.blurb}
                                    </p>
                                </section>
                            </Panel>
                        </Link>
                    );
                })}
            </StyledServicePanels>
        </StyledMDXBody>
    );
};
