import React from 'react';
import { AltLayout } from '../layout/AltLayout';
import { graphql } from 'gatsby';
import { Hero } from '../components/Hero';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';
import { colors, device } from '../components/layout/GlobalStyles';
import { ServicePanels } from '../components/Service-Panels';
import { BluePanel } from '../components/Blue-Panel';
import { Seo } from '../components/Seo';

const PageContainer = styled.article``;

const StyledMDXBody = styled.div`
    padding: 108px 20px 0px;

    /* main service pictures */
    p + div {
        margin: 1em 0em;
        border-radius: 20px;
        img {
            border-radius: 20px;
            margin-bottom: 30px;
        }
    }
    p {
        font-family: 'lato';
    }
    h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: ${colors.blueMedium};
    }
    ul {
        margin: 30px 0px;
        padding-left: 1.5em;
        li {
            font-size: 18px;
            margin: 0.5em 0em;
        }
    }
    @media ${device.tablet} {
        max-width: 750px;
        padding: 108px 20px 0px;
        margin: 0 auto;
        h2 {
            margin-top: 2em;
        }
        /* main service pictures */
        p {
        }
        p + div {
            margin: 1em 0em;
            border-radius: 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptopS} {
        max-width: 750px;
        margin: 0 auto;
        padding-top: 32px;
        /* main service pictures */
        h2 {
            margin-top: 60px;
        }
        p {
            width: 750px;
        }
        p + div {
            margin: 60px 0em 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptop} {
        max-width: 846px;
    }
    @media ${device.laptopL} {
        max-width: 1046px;
        /* main service pictures */
        p {
            width: 1046px;
        }
        p + div {
            margin: 30px 0em 0px;
            img {
                width: 1046px;
                height: 450px;
                border-radius: 20px;
            }
        }
    }
`;

const GroupServices = ({
    data: {
        service: { frontmatter, body },
        groupedServices,
    },
}) => {
    return (
        <>
            <Seo title={frontmatter.meta.title} description={frontmatter.meta.description} />
            <PageContainer>
                <Hero
                    title={frontmatter.title}
                    breadCrumbs={frontmatter.breadCrumbs}
                    breadCrumbsUrl={frontmatter.breadCrumbsUrl}
                    image={frontmatter.images[0]}
                />
                <StyledMDXBody>
                    <MDXRenderer images={frontmatter.images}>{body}</MDXRenderer>
                </StyledMDXBody>
                <ServicePanels services={groupedServices} />
                <BluePanel />
            </PageContainer>
        </>
    );
};

export const query = graphql`
    query ($id: String!, $category: String!) {
        service: mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                category
                title
                breadCrumbs
                breadCrumbsUrl
                slug
                images {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100)
                    }
                }
            }
            body
        }
        groupedServices: allMdx(filter: { frontmatter: { category: { eq: $category } } }) {
            edges {
                node {
                    id
                    frontmatter {
                        slug
                        images {
                            childrenImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                        }
                        title
                        category
                        blurb
                        breadCrumbs
                        icon {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                        }
                    }
                    body
                }
            }
        }
    }
`;

GroupServices.Layout = AltLayout;

export default GroupServices;
